export const PaymentList = () => {
  return [
    {
      id: "1",
      logo: ["bca.jpg"],
      payment_code: "bca_va",
      label: "Bank Transfer - BCA VA",
      payment_type: "Bank Transfer & Virtual Account",
      acquire: "midtrans",
      desc: "",
    },
    {
      id: "2",
      logo: ["echannel.jpg"],
      payment_code: "echannel",
      label: "Bank Transfer - Mandiri Bill Payment",
      payment_type: "Bank Transfer & Virtual Account",
      acquire: "midtrans",
      desc: "",
    },
    {
      id: "3",
      logo: ["bni.jpg"],
      payment_code: "bni_va",
      label: "Bank Transfer - BNI VA",
      payment_type: "Bank Transfer & Virtual Account",
      acquire: "midtrans",
      desc: "",
    },
    {
      id: "4",
      logo: ["permata_va.jpg"],
      payment_code: "permata_va",
      label: "Bank Transfer - Permata VA",
      payment_type: "Bank Transfer & Virtual Account",
      acquire: "midtrans",
      desc: "",
    },
    {
      id: "5",
      logo: ["other_va_1.jpg", "other_va_2.jpg", "other_va_3.jpg"],
      label: "Bank Transfer - Other",
      payment_code: "other_va",
      payment_type: "Bank Transfer & Virtual Account",
      acquire: "midtrans",
      desc: "",
    },
    // {
    //   id: "6",
    //   logo: ["shopeepay.jpg", "spay-later.jpg"],
    //   label: "ShopeePay / SPayLater",
    //   payment_code: "shopeepay",
    //   payment_type: "E-wallet Payment",
    //   acquire: "midtrans",
    //   desc: "",
    // },
    // {
    //   id: "7",
    //   logo: ["gopay.jpg"],
    //   label: "GoPay",
    //   payment_code: "gopay",
    //   payment_type: "E-wallet Payment",
    //   acquire: "midtrans",
    //   desc: "",
    // },
    {
      id: "8",
      logo: ["ovo.jpg"],
      label: "OVO",
      payment_code: "ovo",
      payment_type: "E-wallet Payment",
      acquire: "xendit",
      desc: "",
    },
    {
      id: "9",
      logo: ["qris.jpg"],
      label: "QRIS",
      payment_code: "qris",
      payment_type: "E-wallet Payment",
      acquire: "midtrans",
      desc: "",
    },
    {
      id: "10",
      logo: ["alfamart.jpg"],
      label: "Alfamart Group (Alfamart, Alfamidi, Dan+Dan)",
      payment_code: "alfamart",
      payment_type: "Offline Payment",
      acquire: "midtrans",
      desc: "",
    },
    {
      id: "11",
      logo: ["cc_amex.jpg", "cc_jcb.jpg", "cc_master.jpg", "cc_visa.jpg"],
      label: "Credit/Debit Card",
      payment_code: "credit_card",
      payment_type: "Kredit & Cicilan",
      acquire: "midtrans",
      desc: "",
    },
    {
      id: "12",
      logo: [],
      label: "BCA Installment",
      payment_code: "bca_installment",
      payment_type: "Kredit & Cicilan",
      acquire: "midtrans",
      desc: "",
    },
    {
      id: "13",
      logo: [],
      label: "Mandiri Installment",
      payment_code: "mandiri_installment",
      payment_type: "Kredit & Cicilan",
      acquire: "midtrans",
      desc: "",
    },
    {
      id: "14",
      logo: ["kredivo.jpg"],
      label: "Kredivo",
      payment_code: "kredivo",
      payment_type: "Kredit & Cicilan",
      acquire: "kredivo",
      desc: "",
    },
  ];
};
